@tailwind base;
@tailwind components;
@tailwind utilities;

#root {
  @apply h-screen w-screen;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: auto;
}
